import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutState } from '../../../../core/store/layout/layout.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { IProfileRole } from 'apps/early-stage-office/src/app/core/models/profile.interface';
import { ISimplifiedGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { Router } from '@angular/router';

import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { fadeAnimation } from 'apps/early-stage-office/src/app/core/animations';
import { HeaderProfileComponent } from '../header-profile/header-profile.component';
import { ProfileNavComponent } from '../header-profile/profile-nav/profile-nav.component';
import { NavigationComponent } from '../../navigation/navigation.component';
import { GroupsBadgesComponent } from '../../groups-badges/groups-badges.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconComponent } from '@early-stage/components';

@Component({
    selector: 'es-header-side-nav',
    templateUrl: './header-side-nav.component.html',
    styleUrls: ['./header-side-nav.component.scss', './../../navigation/navigation-item/navigation-item.component.scss', './../../navigation/navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation],
    standalone: true,
    imports: [NgIf, IconComponent, GroupsBadgesComponent, NavigationComponent, ProfileNavComponent, HeaderProfileComponent, AsyncPipe],
})
export class HeaderSideNavComponent implements OnDestroy, OnInit {
    public active: Observable<boolean> | boolean = false;

    @Select(ProfileState.currentRole) public currentRole$: Observable<IProfileRole>;
    @Select(ProfileState.groups) public groups$: Observable<ISimplifiedGroup[]>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    @Input() public color: string = '#000';

    constructor(
        public store: Store,
        public router: Router
    ) {}

    public ngOnInit() {
        disablePageScroll();
    }

    public ngOnDestroy() {
        enablePageScroll();
    }

    public offExpand() {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);
        });
    }
}
